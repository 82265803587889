"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WINTER_STORM_THREATS = exports.WINTER_STORM_STRENGTH_LIST = exports.WINTER_STORM_STRENGTH = void 0;
var WINTER_STORM_STRENGTH;
(function (WINTER_STORM_STRENGTH) {
    WINTER_STORM_STRENGTH["weak"] = "Weak";
    WINTER_STORM_STRENGTH["moderate"] = "Moderate";
    WINTER_STORM_STRENGTH["strong"] = "Strong";
    WINTER_STORM_STRENGTH["veryStrong"] = "Very Strong";
})(WINTER_STORM_STRENGTH || (exports.WINTER_STORM_STRENGTH = WINTER_STORM_STRENGTH = {}));
exports.WINTER_STORM_STRENGTH_LIST = [
    WINTER_STORM_STRENGTH.weak,
    WINTER_STORM_STRENGTH.moderate,
    WINTER_STORM_STRENGTH.strong,
    WINTER_STORM_STRENGTH.veryStrong,
];
exports.WINTER_STORM_THREATS = [
    'Heavy Rain',
    'Rain',
    'Mountain Snow',
    'Thunderstorms',
    'Winds',
    'Lightning',
];
