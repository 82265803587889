"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMPTY_USER = exports.USER_ROLES = void 0;
var USER_ROLES;
(function (USER_ROLES) {
    USER_ROLES["ADMIN"] = "Admin";
    USER_ROLES["TRANSMISSION"] = "Transmission";
    USER_ROLES["VIEWER"] = "Viewer";
})(USER_ROLES || (exports.USER_ROLES = USER_ROLES = {}));
exports.EMPTY_USER = {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    role: USER_ROLES.VIEWER,
};
