"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOutagesColor = exports.getWeatherColor = exports.getOpiLevelColor = exports.getCustomersColor = exports.calculateAllNewLevels = exports.determineSDGEOutageValues = void 0;
const constants_1 = require("../constants");
const determineOPILevel = (outages, type) => {
    let outageType;
    if (type.toLowerCase() === constants_1.OPI_THRESHOLD_TYPES.SDGE) {
        outageType = constants_1.OPI_THRESHOLD_TYPES.SDGE;
    }
    else {
        outageType = constants_1.OPI_THRESHOLD_TYPES.Region;
    }
    if (outages > constants_1.OPI_THRESHOLDS.outage[outageType].count_fcst.max) {
        return constants_1.OPI_LEVELS.extreme.long;
    }
    else if (outages === constants_1.OPI_THRESHOLDS.outage[outageType].count_fcst.min) {
        return constants_1.OPI_LEVELS.normal.long;
    }
    else {
        return constants_1.OPI_LEVELS.elevated.long;
    }
};
const determineRegionLevels = (moment, opiArray, recalc) => {
    let timeFrameArray = [];
    opiArray.forEach((opiEntry, idx) => {
        let outageType;
        if (opiEntry.region.toLowerCase() === constants_1.OPI_THRESHOLD_TYPES.SDGE) {
            outageType = constants_1.OPI_THRESHOLD_TYPES.SDGE;
        }
        else {
            outageType = constants_1.OPI_THRESHOLD_TYPES.Region;
        }
        // Create array with to calculate aggregate customers and outages and assign an iniital OPI level based on those values for each day, in each region
        let timeFrame = moment(opiEntry.valtime_lt_out).format(constants_1.DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER);
        let timeIndex = timeFrameArray.findIndex((p) => moment(p.time_frame).format(constants_1.DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER) === timeFrame);
        if (timeIndex >= 0) {
            timeFrameArray[timeIndex].aggregateCustomerValue += opiEntry.custimp_fcst;
            timeFrameArray[timeIndex].aggregateOutageValue += opiEntry.count_fcst;
            timeFrameArray[timeIndex].level = determineOPILevel(timeFrameArray[timeIndex].aggregateOutageValue, outageType);
        }
        else {
            let entry;
            if (recalc) {
                entry = {
                    time_frame: opiEntry.valtime_lt_out,
                    aggregateCustomerValue: opiEntry.custimp_fcst,
                    aggregateOutageValue: opiEntry.count_fcst,
                    region: opiEntry.region,
                    level: determineOPILevel(opiEntry.count_fcst, opiEntry.region),
                    temp_id: `temp-${opiEntry.region}-${idx}`,
                };
            }
            else {
                entry = {
                    time_frame: opiEntry.valtime_lt_out,
                    aggregateCustomerValue: opiEntry.custimp_fcst,
                    aggregateOutageValue: opiEntry.count_fcst,
                    region: opiEntry.region,
                    level: determineOPILevel(opiEntry.count_fcst, opiEntry.region),
                };
            }
            timeFrameArray.push(entry);
        }
    });
    timeFrameArray.forEach((entry) => {
        delete entry.aggregateCustomerValue;
        delete entry.aggregateOutageValue;
    });
    return timeFrameArray;
};
const calculateAllNewLevels = (allOpiOutages, moment) => {
    // Calculate new levels based on new outage values
    const organizedOutages = constants_1.DISTRICTS_LIST.map((region) => {
        return allOpiOutages.filter((opiEntries) => opiEntries.region.toLowerCase() === region.code);
    });
    const sdgeOutages = allOpiOutages.filter((opiEntries) => opiEntries.region.toLowerCase() === 'sdge');
    const newLevels = organizedOutages.concat([sdgeOutages]).map((region) => {
        return determineRegionLevels(moment, region, true);
    });
    return newLevels.flat();
};
exports.calculateAllNewLevels = calculateAllNewLevels;
const determineSDGEOutageValues = (moment, opiArray, recalc) => {
    const sdgeArray = [];
    const date = moment().toDate();
    opiArray.forEach((opiEntry, idx) => {
        let timeIndex = sdgeArray.findIndex((p) => moment(p.valtime_lt_out).format(constants_1.DATETIME_FORMATS.OPI_OUTAGE_ORIGINAL_FORMAT) ===
            moment(opiEntry.valtime_lt_out).format(constants_1.DATETIME_FORMATS.OPI_OUTAGE_ORIGINAL_FORMAT));
        if (timeIndex >= 0) {
            sdgeArray[timeIndex].custimp_fcst += opiEntry.custimp_fcst;
            sdgeArray[timeIndex].count_fcst += opiEntry.count_fcst;
        }
        else {
            let entry;
            if (recalc) {
                entry = {
                    temp_id: `temp-SDGE-${idx}`,
                    region: 'SDGE',
                    count_fcst: opiEntry.count_fcst,
                    custimp_fcst: opiEntry.custimp_fcst,
                    valtime_lt_out: opiEntry.valtime_lt_out,
                    created_at: date,
                };
            }
            else {
                entry = {
                    region: 'SDGE',
                    count_fcst: opiEntry.count_fcst,
                    custimp_fcst: opiEntry.custimp_fcst,
                    valtime_lt_out: opiEntry.valtime_lt_out,
                    created_at: date,
                };
            }
            sdgeArray.push(entry);
        }
    });
    return sdgeArray;
};
exports.determineSDGEOutageValues = determineSDGEOutageValues;
const getWeatherColor = (value, dataColumnName) => {
    if (dataColumnName === 'som_mode') {
        return undefined;
    }
    else if (dataColumnName) {
        const min = constants_1.OPI_THRESHOLDS.weather[dataColumnName].min;
        const max = constants_1.OPI_THRESHOLDS.weather[dataColumnName].max;
        if (dataColumnName !== 'sbli_min' && dataColumnName !== 'ulli_min') {
            if (value < min) {
                return constants_1.COLORS.fieldGreen;
            }
            else if (value > max) {
                return constants_1.COLORS.energyRed;
            }
            else {
                return constants_1.COLORS.electricOrange;
            }
        }
        else {
            if (value < min) {
                return constants_1.COLORS.energyRed;
            }
            else if (value > max) {
                return constants_1.COLORS.fieldGreen;
            }
            else {
                return constants_1.COLORS.electricOrange;
            }
        }
    }
    return;
};
exports.getWeatherColor = getWeatherColor;
const getOutagesColor = (value, district) => {
    if ((!district && value === constants_1.OPI_THRESHOLDS.outage.region.count_fcst.min) ||
        (district === 'SDGE' && value === constants_1.OPI_THRESHOLDS.outage[district.toLowerCase()].count_fcst.min)) {
        return constants_1.COLORS.fieldGreen;
    }
    else if ((!district && value > constants_1.OPI_THRESHOLDS.outage.region.count_fcst.max) ||
        (district === 'SDGE' && value > constants_1.OPI_THRESHOLDS.outage[district.toLowerCase()].count_fcst.max)) {
        return constants_1.COLORS.energyRed;
    }
    else {
        return constants_1.COLORS.electricOrange;
    }
};
exports.getOutagesColor = getOutagesColor;
const getCustomersColor = (value, district) => {
    if ((!district && value === constants_1.OPI_THRESHOLDS.outage.region.custimp_fcst.min) ||
        (district === 'SDGE' && value === constants_1.OPI_THRESHOLDS.outage[district.toLowerCase()].custimp_fcst.min)) {
        return constants_1.COLORS.fieldGreen;
    }
    else if ((!district && value > constants_1.OPI_THRESHOLDS.outage.region.custimp_fcst.max) ||
        (district === 'SDGE' && value > constants_1.OPI_THRESHOLDS.outage[district.toLowerCase()].custimp_fcst.max)) {
        return constants_1.COLORS.energyRed;
    }
    else {
        return constants_1.COLORS.electricOrange;
    }
};
exports.getCustomersColor = getCustomersColor;
const getOpiLevelColor = (value) => {
    if (value === constants_1.OPI_LEVELS.normal.long) {
        return constants_1.COLORS.fieldGreen;
    }
    else if (value === constants_1.OPI_LEVELS.extreme.long) {
        return constants_1.COLORS.energyRed;
    }
    else {
        return constants_1.COLORS.electricOrange;
    }
};
exports.getOpiLevelColor = getOpiLevelColor;
