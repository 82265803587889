"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FORECAST_MODEL = exports.ALL_FORECAST_STATION_CODES = exports.CURRENT_OBS_STATION_CODES = exports.FORECAST_STATION_CODES = exports.FORECAST_STATIONS = exports.FORECAST_STATIONS_LIST = void 0;
exports.FORECAST_STATIONS_LIST = {
    BRG: { code: 'BRG', name: 'Borrego', area_label: 'Deserts' },
    MVN: { code: 'MVN', name: 'Mission Valley North', area_label: 'Mesas' },
    ECJ: { code: 'ECJ', name: 'El Cajon', area_label: 'Inland Valleys' },
    JUL: { code: 'JUL', name: 'Julian', area_label: 'Mountains' },
    SOB: { code: 'SOB', name: 'Solana Beach', area_label: 'Beaches' },
    RAM: { code: 'RAM', name: 'Ramona', area_label: 'Foothills' },
    MPE: { code: 'MPE', name: 'Camp Elliot', area_label: 'Miramar' },
    CRI: { code: 'CRI', name: 'Cristianitos', area_label: 'Inland Orange County' },
    CPO: { code: 'CPO', name: 'Campo', area_label: 'Backcountry Winds & POP' },
    ANE: { code: 'ANE', name: 'Alpine', area_label: 'additional_station' },
    CBD: { code: 'CBD', name: 'Carlsbad', area_label: 'additional_station' },
    CST: { code: 'CST', name: 'Crest', area_label: 'additional_station' },
    CLN: { code: 'CLN', name: 'Crestline', area_label: 'additional_station' },
    CWD: { code: 'CWD', name: 'Crestwood', area_label: 'additional_station' },
    FBK: { code: 'FBK', name: 'Fallbrook', area_label: 'additional_station' },
    IMP: { code: 'IMP', name: 'Imperial Valley', area_label: 'additional_station' },
    OMB: { code: 'OMB', name: 'Otay Mesa Border', area_label: 'additional_station' },
    RPO: { code: 'RPO', name: 'Round Potrero', area_label: 'additional_station' },
    SCR: { code: 'SCR', name: 'San Clemente Ridge', area_label: 'additional_station' },
    SIL: { code: 'SIL', name: 'Sill Hill', area_label: 'additional_station' },
    MIG: { code: 'MIG', name: 'San Miguel', area_label: 'additional_station' },
};
const additional_stations = [];
for (const key in exports.FORECAST_STATIONS_LIST) {
    if (exports.FORECAST_STATIONS_LIST[key].area_label === 'additional_station')
        additional_stations.push(exports.FORECAST_STATIONS_LIST[key]);
}
exports.FORECAST_STATIONS = {
    beach_station: exports.FORECAST_STATIONS_LIST.SOB,
    desert_station: exports.FORECAST_STATIONS_LIST.BRG,
    foothills_station: exports.FORECAST_STATIONS_LIST.RAM,
    inland_orange_county_station: exports.FORECAST_STATIONS_LIST.CRI,
    inland_valleys_station: exports.FORECAST_STATIONS_LIST.ECJ,
    mesas_station: exports.FORECAST_STATIONS_LIST.MVN,
    miramar_station: exports.FORECAST_STATIONS_LIST.MPE,
    mountains_station: exports.FORECAST_STATIONS_LIST.JUL,
    backcountry_winds_station: Object.assign(Object.assign({}, exports.FORECAST_STATIONS_LIST.CPO), { area_label: 'Backcountry Winds' }),
    pop_station: Object.assign(Object.assign({}, exports.FORECAST_STATIONS_LIST.CPO), { area_label: 'POP' }),
    additional_stations: additional_stations,
};
exports.FORECAST_STATION_CODES = [
    exports.FORECAST_STATIONS.beach_station.code,
    exports.FORECAST_STATIONS.desert_station.code,
    exports.FORECAST_STATIONS.foothills_station.code,
    exports.FORECAST_STATIONS.inland_orange_county_station.code,
    exports.FORECAST_STATIONS.inland_valleys_station.code,
    exports.FORECAST_STATIONS.mesas_station.code,
    exports.FORECAST_STATIONS.miramar_station.code,
    exports.FORECAST_STATIONS.mountains_station.code,
    exports.FORECAST_STATIONS_LIST.CPO.code,
];
exports.CURRENT_OBS_STATION_CODES = [
    exports.FORECAST_STATIONS.beach_station.code,
    exports.FORECAST_STATIONS.mesas_station.code,
    exports.FORECAST_STATIONS.inland_valleys_station.code,
    exports.FORECAST_STATIONS.foothills_station.code,
    exports.FORECAST_STATIONS.mountains_station.code,
    exports.FORECAST_STATIONS.desert_station.code,
];
const all_codes = [];
for (const key in exports.FORECAST_STATIONS_LIST) {
    all_codes.push(exports.FORECAST_STATIONS_LIST[key].code);
}
exports.ALL_FORECAST_STATION_CODES = all_codes;
var FORECAST_MODEL;
(function (FORECAST_MODEL) {
    FORECAST_MODEL["DEFAULT"] = "displayed";
    FORECAST_MODEL["NBM"] = "nbm";
    FORECAST_MODEL["ECMWF"] = "ecmwf";
    FORECAST_MODEL["NDFD"] = "ndfd";
})(FORECAST_MODEL || (exports.FORECAST_MODEL = FORECAST_MODEL = {}));
