"use strict";
// NO preceding slash - these are path components and will be inserted as ${HOST_DOMAIN}/${PATH}/etc
Object.defineProperty(exports, "__esModule", { value: true });
exports.POLYGONS = exports.CLIENT_CHECK = exports.CONFIG = exports.POWER = exports.IMAGES = exports.API = exports.PSPS = exports.TWITTER = exports.WIDGETS = exports.AUTHENTICATE = exports.WEATHER_BRIEFING = exports.CHECK_USER_AUTHENTICATED = exports.LOGOUT = exports.LOGIN = exports.USERS = exports.CAMERAS = exports.SEVEN_DAY_FORECAST = exports.FORECAST = exports.SUNRISE_SUNSET = exports.PUSH_NOTIFICATIONS = exports.SAWTI = exports.SAFETY_MESSAGE = exports.WINTER_STORM_THREAT = exports.THUNDERSTORM_THREAT = exports.PROJECT_ACTIVITY_LEVEL = exports.LOW_TEMP = exports.WIND_GUSTS = exports.RAINFALL = exports.SNOWFALL = exports.RED_FLAG = exports.TIDES = exports.HUMIDITY = exports.TEMPERATURES = exports.WINDS_STREAMLINES = exports.WINDS_FORECASTED_GUSTS = exports.WINDS_CURRENT_GUSTS = exports.WINDS_TOP_CURRENT = exports.WINDS_TOP_MAX = exports.WINDS = exports.OBSERVATIONS = exports.AQI = exports.OPI = exports.FPI = void 0;
const FPI = 'fpi';
exports.FPI = FPI;
const OPI = 'opi';
exports.OPI = OPI;
const AQI = 'aqi';
exports.AQI = AQI;
const OBSERVATIONS = 'observations';
exports.OBSERVATIONS = OBSERVATIONS;
const WINDS = 'winds';
exports.WINDS = WINDS;
const WINDS_TOP_MAX = 'winds/top-5-max-gusts';
exports.WINDS_TOP_MAX = WINDS_TOP_MAX;
const WINDS_CURRENT_GUSTS = 'winds/current-gusts';
exports.WINDS_CURRENT_GUSTS = WINDS_CURRENT_GUSTS;
const WINDS_FORECASTED_GUSTS = 'winds/forecasted-gusts';
exports.WINDS_FORECASTED_GUSTS = WINDS_FORECASTED_GUSTS;
const WINDS_TOP_CURRENT = 'winds/top-5-current-gusts';
exports.WINDS_TOP_CURRENT = WINDS_TOP_CURRENT;
const WINDS_STREAMLINES = 'winds/streamlines';
exports.WINDS_STREAMLINES = WINDS_STREAMLINES;
const TEMPERATURES = 'temps';
exports.TEMPERATURES = TEMPERATURES;
const HUMIDITY = 'humidity';
exports.HUMIDITY = HUMIDITY;
const TIDES = 'tides';
exports.TIDES = TIDES;
const RED_FLAG = 'red-flag';
exports.RED_FLAG = RED_FLAG;
const SNOWFALL = 'snowfall-forecast';
exports.SNOWFALL = SNOWFALL;
const RAINFALL = 'rainfall-forecast';
exports.RAINFALL = RAINFALL;
const WIND_GUSTS = 'wind-gust-forecast';
exports.WIND_GUSTS = WIND_GUSTS;
const LOW_TEMP = 'low-temp-forecast';
exports.LOW_TEMP = LOW_TEMP;
const PROJECT_ACTIVITY_LEVEL = 'project-activity-level';
exports.PROJECT_ACTIVITY_LEVEL = PROJECT_ACTIVITY_LEVEL;
const PUSH_NOTIFICATIONS = 'push-notifications';
exports.PUSH_NOTIFICATIONS = PUSH_NOTIFICATIONS;
const FORECAST = 'forecast';
exports.FORECAST = FORECAST;
const SEVEN_DAY_FORECAST = 'seven-day-forecast';
exports.SEVEN_DAY_FORECAST = SEVEN_DAY_FORECAST;
const SUNRISE_SUNSET = 'sunrise-sunset';
exports.SUNRISE_SUNSET = SUNRISE_SUNSET;
const SAWTI = 'sawti';
exports.SAWTI = SAWTI;
const THUNDERSTORM_THREAT = 'thunderstorm-threat';
exports.THUNDERSTORM_THREAT = THUNDERSTORM_THREAT;
const WINTER_STORM_THREAT = 'winter-storm-threat';
exports.WINTER_STORM_THREAT = WINTER_STORM_THREAT;
const SAFETY_MESSAGE = 'safety-message';
exports.SAFETY_MESSAGE = SAFETY_MESSAGE;
const USERS = 'users';
exports.USERS = USERS;
const CAMERAS = 'cameras';
exports.CAMERAS = CAMERAS;
const LOGIN = 'login';
exports.LOGIN = LOGIN;
const LOGOUT = 'logout';
exports.LOGOUT = LOGOUT;
const CHECK_USER_AUTHENTICATED = 'check';
exports.CHECK_USER_AUTHENTICATED = CHECK_USER_AUTHENTICATED;
const WEATHER_BRIEFING = 'weather-briefing';
exports.WEATHER_BRIEFING = WEATHER_BRIEFING;
const AUTHENTICATE = 'authenticate';
exports.AUTHENTICATE = AUTHENTICATE;
const WIDGETS = 'widgets';
exports.WIDGETS = WIDGETS;
const TWITTER = 'twitter';
exports.TWITTER = TWITTER;
const PSPS = 'psps';
exports.PSPS = PSPS;
const API = 'api';
exports.API = API;
const IMAGES = 'images';
exports.IMAGES = IMAGES;
const POWER = 'power';
exports.POWER = POWER;
const CONFIG = 'config';
exports.CONFIG = CONFIG;
const CLIENT_CHECK = 'client-check';
exports.CLIENT_CHECK = CLIENT_CHECK;
const POLYGONS = 'polygons';
exports.POLYGONS = POLYGONS;
