"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OPI_THRESHOLDS = exports.OPI_THRESHOLD_TYPES = exports.OPI_TYPES = exports.OPI_LEVEL_LIST = exports.OPI_LEVELS = exports.OPI_LEVEL_DESCRIPTION = void 0;
exports.OPI_LEVEL_DESCRIPTION = {
    normal: 'The outage potential is normal with weather-related outages unlikely.',
    elevated: 'The outage potential is elevated with weather-related outages possible. Contributing factor(s) to outages may include thunderstorms.',
    extreme: 'The outage potential is extreme with weather-related outages likely. Contributing factor(s) to outages may include thunderstorms.',
};
exports.OPI_LEVELS = {
    normal: { short: 'NO', long: 'Normal' },
    elevated: { short: 'EL', long: 'Elevated' },
    extreme: { short: 'EX', long: 'Extreme' },
};
exports.OPI_LEVEL_LIST = [
    exports.OPI_LEVELS.normal.long,
    exports.OPI_LEVELS.elevated.long,
    exports.OPI_LEVELS.extreme.long,
];
var OPI_TYPES;
(function (OPI_TYPES) {
    OPI_TYPES["Outage"] = "outage";
    OPI_TYPES["Weather"] = "weather";
})(OPI_TYPES || (exports.OPI_TYPES = OPI_TYPES = {}));
var OPI_THRESHOLD_TYPES;
(function (OPI_THRESHOLD_TYPES) {
    OPI_THRESHOLD_TYPES["Region"] = "region";
    OPI_THRESHOLD_TYPES["SDGE"] = "sdge";
})(OPI_THRESHOLD_TYPES || (exports.OPI_THRESHOLD_TYPES = OPI_THRESHOLD_TYPES = {}));
exports.OPI_THRESHOLDS = {
    weather: {
        t2m_max: { min: 85, max: 95 },
        td2m_max: { min: 60, max: 69 },
        heatidx_max_pct: { min: 95, max: 97 },
        wgust_max1: { min: 30, max: 39 },
        wgust_max2: { min: 45, max: 54 },
        wgust_max_pct: { min: 95, max: 97 },
        precip_rate_max: { min: 1, max: 2 },
        precip_12hr_accum: { min: 1, max: 2 },
        precip_12hr_accum_pct: { min: 95, max: 97 },
        sbli_min: { min: -2, max: 0 },
        ulli_min: { min: -2, max: 0 },
        sfccape_max: { min: 0, max: 500 },
        ulcape_max: { min: 0, max: 100 },
    },
    outage: {
        region: {
            count_fcst: { min: 0, max: 2 },
            custimp_fcst: { min: 0, max: 500 },
        },
        sdge: {
            count_fcst: { min: 0, max: 8 },
            custimp_fcst: { min: 0, max: 5000 },
        },
    },
};
