"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMPTY_CAMERA = exports.CAMERA_TYPES = void 0;
const CAMERA_TYPES = {
    general: 'camera',
    wildfire: 'alertwildfirecamera',
};
exports.CAMERA_TYPES = CAMERA_TYPES;
const EMPTY_CAMERA = {
    name: '',
    latitude: 0,
    longitude: 0,
    elevation: 0,
    north: '',
    northeast: '',
    east: '',
    southeast: '',
    south: '',
    southwest: '',
    west: '',
    northwest: '',
    type: '',
    cam1: '',
    cam2: '',
    cam3: '',
    cam4: '',
    disabled: false,
    created_at: new Date(),
};
exports.EMPTY_CAMERA = EMPTY_CAMERA;
