"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROJECT_ACTIVITY_LEVEL_ZONES = exports.PROJECT_ACTIVITY_LEVEL_OPTIONS = exports.PAL_LEVELS = void 0;
exports.PAL_LEVELS = {
    gray: { long: 'Gray (NA)', short: 'NA' },
    green: { long: 'Light Green (A)', short: 'A' },
    blue: { long: 'Blue (B)', short: 'B' },
    yellow: { long: 'Yellow (C)', short: 'C' },
    orange: { long: 'Orange (D)', short: 'D' },
    brown: { long: 'Brown (E)', short: 'E' },
    red: { long: 'Red (EV)', short: 'EV' },
};
exports.PROJECT_ACTIVITY_LEVEL_OPTIONS = [
    exports.PAL_LEVELS.gray.long,
    exports.PAL_LEVELS.green.long,
    exports.PAL_LEVELS.blue.long,
    exports.PAL_LEVELS.yellow.long,
    exports.PAL_LEVELS.orange.long,
    exports.PAL_LEVELS.brown.long,
    exports.PAL_LEVELS.red.long,
];
exports.PROJECT_ACTIVITY_LEVEL_ZONES = ['INLAND', 'MOUNTAIN'];
