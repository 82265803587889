"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CODES = void 0;
var CODES;
(function (CODES) {
    CODES["usernameExists"] = "Username already exists.";
    CODES["emailExists"] = "Email already exists.";
    CODES["dataNotValid"] = "Data not valid.";
    CODES["schmeaNotValid"] = "Invalid schema.";
    CODES["errorOccurred"] = "An error occurred.";
    CODES["allFieldsRequired"] = "All fields are required.";
    CODES["SAVED"] = "Saved.";
    CODES["FETCH_SUCCESS"] = "New data imported. Refreshing page.";
    CODES["PUBLISHED"] = "Published";
    CODES["RECALC"] = "Recalculating...";
    CODES["ERROR_DB_FETCH"] = "Error retrieving data.";
    CODES["ERROR_SAVE"] = "Error saving data.";
    CODES["ERROR_PUBLISH"] = "Error publishing data.";
    CODES["ERROR_FETCH"] = "Error importing new data.";
})(CODES || (exports.CODES = CODES = {}));
