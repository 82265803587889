"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NEW_WEATHER_BRIEF = exports.PUBLISH_STATUS = void 0;
var PUBLISH_STATUS;
(function (PUBLISH_STATUS) {
    PUBLISH_STATUS["preview"] = "Preview";
    PUBLISH_STATUS["unpublished"] = "Unpublished";
    PUBLISH_STATUS["published"] = "Published";
    PUBLISH_STATUS["archived"] = "Archived";
})(PUBLISH_STATUS || (exports.PUBLISH_STATUS = PUBLISH_STATUS = {}));
exports.NEW_WEATHER_BRIEF = {
    report_date: new Date(),
    safety_point: {
        type: 'doc',
        content: [],
    },
    executive_summ: {
        type: 'doc',
        content: [],
    },
    forecast_discussion: {
        type: 'doc',
        content: [],
    },
    fire_discussion: {
        type: 'doc',
        content: [],
    },
    heat_index_discussion: {
        type: 'doc',
        content: [],
    },
    created_at: new Date(),
    include_fpi: true,
    include_7day_fpi: true,
    include_tide_report: true,
    include_sunrise_sunset: true,
    include_forecast: true,
    status: PUBLISH_STATUS.preview,
    data: {},
};
