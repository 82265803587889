"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WIND_DIRECTIONS = void 0;
exports.WIND_DIRECTIONS = [
    'N',
    'E',
    'S',
    'W',
    'NW',
    'NE',
    'SW',
    'SE',
    'SSW',
    'SSE',
    'NNW',
    'NNE',
    'ESE',
    'ENE',
    'WNW',
    'WSW',
];
