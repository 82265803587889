"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.csvParser = void 0;
const constants_1 = require("../constants");
//var csv is the CSV file with headers
const csvParser = (csv, dataType) => {
    var lines = csv.split('\n');
    var result = [];
    var headers = lines[0].split(',');
    for (var i = 1; i < lines.length; i++) {
        var obj = {};
        // Remove blank lines in CSV file
        if (lines[i] !== '') {
            var currentline = lines[i].split(',');
            for (var j = 0; j < headers.length; j++) {
                if (j > 0 && dataType === constants_1.OPI_TYPES.Weather) {
                    if (j === 1) {
                        obj[headers[j]] = parseInt(currentline[j]);
                    }
                    else {
                        obj[headers[j]] = parseFloat(currentline[j]);
                    }
                }
                else if (j > 0 && dataType === constants_1.OPI_TYPES.Outage) {
                    if (j === 1) {
                        obj[headers[j]] = parseFloat(currentline[j]);
                    }
                    else {
                        obj[headers[j]] = parseInt(currentline[j]);
                    }
                }
                else {
                    obj[headers[j]] = currentline[j];
                }
            }
            result.push(obj);
        }
    }
    return result;
};
exports.csvParser = csvParser;
