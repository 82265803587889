import { useEditor, EditorContent } from '@tiptap/react';
import Text from '@tiptap/extension-text';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Paragraph from '@tiptap/extension-paragraph';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import Italic from '@tiptap/extension-italic';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Blockquote from '@tiptap/extension-blockquote';
import Heading from '@tiptap/extension-heading';
import ListItem from '@tiptap/extension-list-item';
import Image from '@tiptap/extension-image';
import History from '@tiptap/extension-history';

const RichTextRenderer = (content: any) => {
  const editor = useEditor({
    extensions: [
      Text,
      Document,
      Paragraph,
      Link,
      Image,
      Underline,
      Italic,
      Bold,
      BulletList,
      OrderedList,
      Blockquote,
      Dropcursor,
      Heading,
      History,
      ListItem,
    ],
    content: content.content,
    editable: false,
  });

  return <EditorContent editor={editor} />;
};

export default RichTextRenderer;
