"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fpiLongLevelFromValue = exports.fpiShortLevelFromValue = exports.calcWeatherComponent = exports.greenessColor = exports.lfmColor = exports.setFpiDataClass = exports.fpiColor = exports.weatherComponentColor = exports.drynessColor = exports.calcFpi = exports.setupLfm = void 0;
const constants_1 = require("../constants");
function setupLfm(lfm) {
    const lfmDecimal = lfm / 100;
    const lfmFinalDecimal = (Math.round(lfmDecimal * 100) / 100).toFixed(2);
    return lfmFinalDecimal;
}
exports.setupLfm = setupLfm;
// Formula from the client for FPI
// FPI = (DL/LFM) + Greenness + WX Component
// Example:
// DL = 3
// LFM = 65% = 0.65
// Greenness = 5
// WX Component = 4
// FPI = (3/0.65) + 5 + 4
// FPI =  4.6 + 5 + 4
// FPI = 13.6 which rounds up to 14
// FPI = 14
function calcFpi(drynessLevel, greenness, weatherComponent, lfm) {
    let lfmVal;
    let dryVal;
    let wxVal = weatherComponent ? weatherComponent : 0;
    // For Dryness Level, the scale is: 1, 2, 3
    // Allow users to enter 0 for the Dryness Level Input
    // because the validation has functionality issues if we force all
    // entries of 0 to be 1 on the input.
    // If the user enters 0, we default to the lowest
    // value on the available scale.
    if (drynessLevel === 0) {
        dryVal = 1;
    }
    else {
        dryVal = drynessLevel;
    }
    // Allow the user to input 0, but if a
    // value of 0 is entered we use a very small
    // decimal value for LFM instead of 0. This is an
    // edge case as the lowest LFM value on the scale is
    // < 0.60
    if (!lfm || Math.round(lfm) === 0) {
        lfmVal = 0.01;
    }
    else {
        lfmVal = lfm;
    }
    const fpi = dryVal / lfmVal + Math.round(greenness) + wxVal;
    return Math.round(fpi);
}
exports.calcFpi = calcFpi;
function drynessColor(value) {
    if (Number(value) < 2) {
        return constants_1.FPI_COLORS.drynessGreen;
    }
    else if (Number(value) >= 2 && Number(value) < 3) {
        return constants_1.FPI_COLORS.drynessYellow;
    }
    else if (Number(value) >= 3) {
        return constants_1.FPI_COLORS.drynessRed;
    }
    else {
        return constants_1.FPI_COLORS.default;
    }
}
exports.drynessColor = drynessColor;
function weatherComponentColor(value) {
    if (Number(value) < 1) {
        return constants_1.FPI_COLORS.weatherComponent0;
    }
    else if (Number(value) >= 1 && Number(value) < 2) {
        return constants_1.FPI_COLORS.weatherComponent1;
    }
    else if (Number(value) >= 2 && Number(value) < 3) {
        return constants_1.FPI_COLORS.weatherComponent2;
    }
    else if (Number(value) >= 3 && Number(value) < 4) {
        return constants_1.FPI_COLORS.weatherComponent3;
    }
    else if (Number(value) >= 4 && Number(value) < 5) {
        return constants_1.FPI_COLORS.weatherComponent4;
    }
    else if (Number(value) >= 5 && Number(value) < 6) {
        return constants_1.FPI_COLORS.weatherComponent5;
    }
    else if (Number(value) >= 6) {
        return constants_1.FPI_COLORS.weatherComponent6;
    }
    else {
        return constants_1.FPI_COLORS.default;
    }
}
exports.weatherComponentColor = weatherComponentColor;
function fpiColor(value) {
    if (Number(value) <= 11) {
        return constants_1.FPI_COLORS.fpiColorGreen;
    }
    else if (Number(value) >= 12 && Number(value) <= 13) {
        return constants_1.FPI_COLORS.fpiColorYellow;
    }
    else if (Number(value) === 14) {
        return constants_1.FPI_COLORS.fpiColorOrange;
    }
    else if (Number(value) >= 15) {
        return constants_1.FPI_COLORS.fpiColorRed;
    }
    else {
        return constants_1.FPI_COLORS.default;
    }
}
exports.fpiColor = fpiColor;
function setFpiDataClass(dataPointName, value) {
    if (dataPointName.includes('rh2m')) {
        if (Number(value) < 6) {
            return constants_1.FPI_COLORS.fpiDataColorPurple;
        }
        else if (Number(value) >= 6 && Number(value) < 16) {
            return constants_1.FPI_COLORS.fpiDataColorRed;
        }
        else if (Number(value) >= 16 && Number(value) < 31) {
            return constants_1.FPI_COLORS.fpiDataColorYellow;
        }
        else if (Number(value) >= 31) {
            return constants_1.FPI_COLORS.fpiDataColorGreen;
        }
        else {
            return constants_1.FPI_COLORS.default;
        }
    }
    else if (dataPointName.includes('dewdepr2m')) {
        if (Number(value) < 20) {
            return 'fpiDataColorGreen';
        }
        else if (Number(value) >= 20 && Number(value) < 40) {
            return constants_1.FPI_COLORS.fpiDataColorYellow;
        }
        else if (Number(value) >= 40 && Number(value) < 50) {
            return constants_1.FPI_COLORS.fpiDataColorRed;
        }
        else if (Number(value) >= 50) {
            return constants_1.FPI_COLORS.fpiDataColorPurple;
        }
        else {
            return constants_1.FPI_COLORS.default;
        }
    }
    else if (dataPointName === 't2m_max_fahrenheit') {
        if (Number(value) < 80) {
            return constants_1.FPI_COLORS.fpiDataColorGreen;
        }
        else if (Number(value) >= 80 && Number(value) < 90) {
            return constants_1.FPI_COLORS.fpiDataColorYellow;
        }
        else if (Number(value) >= 90 && Number(value) < 100) {
            return constants_1.FPI_COLORS.fpiDataColorRed;
        }
        else if (Number(value) >= 100) {
            return constants_1.FPI_COLORS.fpiDataColorPurple;
        }
        else {
            return constants_1.FPI_COLORS.default;
        }
    }
    else if (dataPointName === 'wgust10m_max_mph') {
        if (Number(value) < 26) {
            return constants_1.FPI_COLORS.fpiDataColorGreen;
        }
        else if (Number(value) >= 26 && Number(value) < 36) {
            return constants_1.FPI_COLORS.fpiDataColorYellow;
        }
        else if (Number(value) >= 36 && Number(value) < 46) {
            return constants_1.FPI_COLORS.fpiDataColorRed;
        }
        else if (Number(value) >= 46) {
            return constants_1.FPI_COLORS.fpiDataColorPurple;
        }
        else {
            return constants_1.FPI_COLORS.default;
        }
    }
    else if (dataPointName === 'rh850mb_min_pct') {
        if (Number(value) < 6) {
            return constants_1.FPI_COLORS.fpiDataColorGreen;
        }
        else if (Number(value) >= 6 && Number(value) < 16) {
            return constants_1.FPI_COLORS.fpiDataColorYellow;
        }
        else if (Number(value) >= 16 && Number(value) < 31) {
            return constants_1.FPI_COLORS.fpiDataColorRed;
        }
        else if (Number(value) >= 31) {
            return constants_1.FPI_COLORS.fpiDataColorPurple;
        }
        else {
            return constants_1.FPI_COLORS.default;
        }
    }
    else if (dataPointName === 'wspd10m_max_mph') {
        if (Number(value) < 11) {
            return constants_1.FPI_COLORS.fpiDataColorGreen;
        }
        else if (Number(value) >= 11 && Number(value) < 17) {
            return constants_1.FPI_COLORS.fpiDataColorYellow;
        }
        else if (Number(value) >= 17 && Number(value) < 29) {
            return constants_1.FPI_COLORS.fpiDataColorRed;
        }
        else if (Number(value) >= 29) {
            return constants_1.FPI_COLORS.fpiDataColorPurple;
        }
        else {
            return constants_1.FPI_COLORS.default;
        }
    }
    return undefined;
}
exports.setFpiDataClass = setFpiDataClass;
function lfmColor(value) {
    if (value < 0.6) {
        return constants_1.FPI_COLORS.fuelsRed;
    }
    else if (value >= 0.6 && value <= 0.69) {
        return constants_1.FPI_COLORS.fuelsOrange;
    }
    else if (value >= 0.7 && value <= 0.79) {
        return constants_1.FPI_COLORS.fuelsYellow;
    }
    else if (value >= 0.8 && value <= 0.89) {
        return constants_1.FPI_COLORS.fuelsGreen;
    }
    else if (value >= 0.9 && value <= 0.99) {
        return constants_1.FPI_COLORS.fuelsBlueLight;
    }
    else if (value >= 1) {
        return constants_1.FPI_COLORS.fuelsBlueDark;
    }
    else {
        return constants_1.FPI_COLORS.default;
    }
}
exports.lfmColor = lfmColor;
function greenessColor(greenness) {
    let value = Number(greenness);
    if (value >= 5) {
        return constants_1.FPI_COLORS.fuelsRed;
    }
    else if (value < 5 && value >= 4) {
        return constants_1.FPI_COLORS.fuelsOrange;
    }
    else if (value < 4 && value >= 3) {
        return constants_1.FPI_COLORS.fuelsYellow;
    }
    else if (value < 3 && value >= 2) {
        return constants_1.FPI_COLORS.fuelsGreen;
    }
    else if (value < 2 && value >= 1) {
        return constants_1.FPI_COLORS.fuelsBlueLight;
    }
    else if (value < 1 && value >= 0) {
        return constants_1.FPI_COLORS.fuelsBlueDark;
    }
    else {
        return constants_1.FPI_COLORS.default;
    }
}
exports.greenessColor = greenessColor;
/**
 *
 * A.Humes 8/2023: After the release of the rebuild site, the client noted that the
 * WX Component in the Admin > FPI page should allow them to edit without getting
 * overwritten on a subsequent click of the 'Recalculate' button. With that change
 * the `calcWeatherComponent` function is no longer used in the code. Not removing,
 * but keeping in case for any future need. See https://jira.valtech.com/browse/SFP-1145.
 */
function calcWeatherComponent(windSpeed, dewpointDepression) {
    if (!windSpeed || windSpeed < 6) {
        if (!dewpointDepression || dewpointDepression < 10) {
            return 0;
        }
        else if (dewpointDepression >= 10 && dewpointDepression < 20) {
            return 2;
        }
        else if (dewpointDepression >= 20 && dewpointDepression < 30) {
            return 3;
        }
        else if (dewpointDepression >= 30 && dewpointDepression < 40) {
            return 3;
        }
        else if (dewpointDepression >= 40 && dewpointDepression < 50) {
            return 3;
        }
        else if (dewpointDepression >= 50) {
            return 3;
        }
    }
    else if (windSpeed >= 6 && windSpeed < 11) {
        if (!dewpointDepression || dewpointDepression < 10) {
            return 1;
        }
        else if (dewpointDepression >= 10 && dewpointDepression < 20) {
            return 2;
        }
        else if (dewpointDepression >= 20 && dewpointDepression < 30) {
            return 3;
        }
        else if (dewpointDepression >= 30 && dewpointDepression < 40) {
            return 3;
        }
        else if (dewpointDepression >= 40 && dewpointDepression < 50) {
            return 3;
        }
        else if (dewpointDepression >= 50) {
            return 3;
        }
    }
    else if (windSpeed >= 11 && windSpeed < 17) {
        if (!dewpointDepression || dewpointDepression < 10) {
            return 1;
        }
        else if (dewpointDepression >= 10 && dewpointDepression < 20) {
            return 2;
        }
        else if (dewpointDepression >= 20 && dewpointDepression < 30) {
            return 3;
        }
        else if (dewpointDepression >= 30 && dewpointDepression < 40) {
            return 3;
        }
        else if (dewpointDepression >= 40 && dewpointDepression < 50) {
            return 3;
        }
        else if (dewpointDepression >= 50) {
            return 4;
        }
        else {
            return 4;
        }
    }
    else if (windSpeed >= 17 && windSpeed < 23) {
        if (!dewpointDepression || dewpointDepression < 10) {
            return 1;
        }
        else if (dewpointDepression >= 10 && dewpointDepression < 20) {
            return 2;
        }
        else if (dewpointDepression >= 20 && dewpointDepression < 30) {
            return 3;
        }
        else if (dewpointDepression >= 30 && dewpointDepression < 40) {
            return 3;
        }
        else if (dewpointDepression >= 40 && dewpointDepression < 50) {
            return 4;
        }
        else if (dewpointDepression >= 50) {
            return 5;
        }
    }
    else if (windSpeed >= 23 && windSpeed < 29) {
        if (!dewpointDepression || dewpointDepression < 10) {
            return 1;
        }
        else if (dewpointDepression >= 10 && dewpointDepression < 19) {
            return 2;
        }
        else if (dewpointDepression >= 20 && dewpointDepression < 29) {
            return 3;
        }
        else if (dewpointDepression >= 30 && dewpointDepression < 39) {
            return 4;
        }
        else if (dewpointDepression >= 40 && dewpointDepression < 49) {
            return 5;
        }
        else if (dewpointDepression >= 50) {
            return 5;
        }
    }
    else if (windSpeed >= 29) {
        if (!dewpointDepression || dewpointDepression < 10) {
            return 2;
        }
        else if (dewpointDepression >= 10 && dewpointDepression < 19) {
            return 3;
        }
        else if (dewpointDepression >= 20 && dewpointDepression < 29) {
            return 4;
        }
        else if (dewpointDepression >= 30 && dewpointDepression < 39) {
            return 5;
        }
        else if (dewpointDepression >= 40 && dewpointDepression < 49) {
            return 5;
        }
        else if (dewpointDepression >= 50) {
            return 6;
        }
    }
    return undefined;
}
exports.calcWeatherComponent = calcWeatherComponent;
function fpiShortLevelFromValue(value) {
    if (Number(value) <= 11) {
        return constants_1.FPI_LEVELS.normal.short;
    }
    else if (Number(value) >= 12 && Number(value) <= 13) {
        return constants_1.FPI_LEVELS.elevated_low.short;
    }
    else if (Number(value) === 14) {
        return constants_1.FPI_LEVELS.elevated_high.short;
    }
    else if (Number(value) >= 15) {
        return constants_1.FPI_LEVELS.extreme.short;
    }
    else {
        return constants_1.FPI_LEVELS.normal.short;
    }
}
exports.fpiShortLevelFromValue = fpiShortLevelFromValue;
function fpiLongLevelFromValue(value) {
    if (Number(value) <= 11) {
        return constants_1.FPI_LEVELS.normal.long;
    }
    else if (Number(value) >= 12 && Number(value) <= 13) {
        return constants_1.FPI_LEVELS.elevated_low.long;
    }
    else if (Number(value) === 14) {
        return constants_1.FPI_LEVELS.elevated_high.long;
    }
    else if (Number(value) >= 15) {
        return constants_1.FPI_LEVELS.extreme.long;
    }
    else {
        return constants_1.FPI_LEVELS.normal.long;
    }
}
exports.fpiLongLevelFromValue = fpiLongLevelFromValue;
