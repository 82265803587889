"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SAWTI_LEVELS = exports.SAWTI_LEVEL_OBJS = void 0;
exports.SAWTI_LEVEL_OBJS = {
    none: { short: 'N', long: 'No Rating' },
    marginal: { short: 'MA', long: 'Marginal' },
    moderate: { short: 'MO', long: 'Moderate' },
    high: { short: 'H', long: 'High' },
    extreme: { short: 'EX', long: 'Extreme' },
};
exports.SAWTI_LEVELS = [
    exports.SAWTI_LEVEL_OBJS.none.long,
    exports.SAWTI_LEVEL_OBJS.marginal.long,
    exports.SAWTI_LEVEL_OBJS.moderate.long,
    exports.SAWTI_LEVEL_OBJS.high.long,
    exports.SAWTI_LEVEL_OBJS.extreme.long,
];
