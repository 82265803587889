import axios from 'axios';

const getApiData = async (url: string) => {
  return await axios.get(url);
};

const getAuthenticatedApiData = async (url: string) => {
  return await axios.get(url, { withCredentials: true });
};

const postApiData = async (url: string, payload: any) => {
  return await axios.post(url, payload, { withCredentials: true });
};

const putApiData = async (url: string, payload: any) => {
  return await axios.put(url, payload, { withCredentials: true });
};

const deleteApiData = async (url: string, payload?: any) => {
  return await axios.delete(url, { data: payload, withCredentials: true });
};

export { getApiData, postApiData, getAuthenticatedApiData, putApiData, deleteApiData };
