"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AQI_CITIES = exports.AQI_CATEGORIES = void 0;
var AQI_CATEGORIES;
(function (AQI_CATEGORIES) {
    AQI_CATEGORIES["Category_1"] = "Good";
    AQI_CATEGORIES["Category_2"] = "Moderate";
    AQI_CATEGORIES["Category_3"] = "Unhealthy for Sensitive Groups";
    AQI_CATEGORIES["Category_4"] = "Unhealthy";
    AQI_CATEGORIES["Category_5"] = "Very Unhealthy";
    AQI_CATEGORIES["Category_6"] = "Hazardous";
})(AQI_CATEGORIES || (exports.AQI_CATEGORIES = AQI_CATEGORIES = {}));
exports.AQI_CITIES = [
    {
        city: 'Alpine',
        zipcode: '91901',
        lat: 32.8282,
        long: -116.7543,
    },
    {
        city: 'Chula Vista',
        zipcode: '91913',
        lat: 32.6513,
        long: -116.9852,
    },
    {
        city: 'Del Mar',
        zipcode: '92014',
        lat: 32.9665,
        long: -117.249,
    },
    {
        city: 'Downtown',
        zipcode: '92101',
        lat: 32.7185,
        long: -117.1593,
    },
    {
        city: 'El Cajon',
        zipcode: '92021',
        lat: 32.8178,
        long: -116.9223,
    },
    {
        city: 'Escondido',
        zipcode: '92027',
        lat: 33.1388,
        long: -117.052,
    },
    {
        city: 'Otay Mesa',
        zipcode: '92154',
        lat: 32.5753,
        long: -117.0707,
    },
    {
        city: 'Kearney Mesa',
        zipcode: '92126',
        lat: 32.9161,
        long: -117.1402,
    },
    {
        city: 'Oceanside',
        zipcode: '92058',
        lat: 33.1959,
        long: -117.3795,
    },
    {
        city: 'Carlsbad',
        zipcode: '92008',
        lat: 33.1602,
        long: -117.325,
    },
    {
        city: 'Coronado',
        zipcode: '92118',
        lat: 32.6807,
        long: -117.1698,
    },
    {
        city: 'Encinitas',
        zipcode: '92007',
        lat: 33.023,
        long: -117.2745,
    },
    {
        city: 'Imperial Beach',
        zipcode: '91932',
        lat: 32.5783,
        long: -117.1148,
    },
    {
        city: 'La Mesa',
        zipcode: '91942',
        lat: 32.7835,
        long: -117.0189,
    },
    {
        city: 'Lemon Grove',
        zipcode: '91945',
        lat: 32.7332,
        long: -117.0326,
    },
    {
        city: 'National City',
        zipcode: '91950',
        lat: 32.6749,
        long: -117.0897,
    },
    {
        city: 'Poway',
        zipcode: '92064',
        lat: 32.9756,
        long: -117.0402,
    },
    {
        city: 'San Marcos',
        zipcode: '92069',
        lat: 33.1444,
        long: -117.1697,
    },
    {
        city: 'Santee',
        zipcode: '92071',
        lat: 32.8486,
        long: -116.9862,
    },
    {
        city: 'Solana Beach',
        zipcode: '92075',
        lat: 32.9937,
        long: -117.2598,
    },
    {
        city: 'Vista',
        zipcode: '92084',
        lat: 33.2131,
        long: -117.2243,
    },
];
