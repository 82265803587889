"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FPI_COLORS = exports.COLORS = void 0;
const COLORS = {
    energyRed: '#C8003E',
    electricOrange: '#F39B31',
    accessibleYellow: '#F8DF4F',
    fieldGreen: '#58B947',
    middaySky: '#009ADE',
    brown: '#966049',
    purple: '#7030A2',
    slate: '#D9D9D9',
    menuBackground: '#f8f8f8',
    menuBorder: '#e7e7e7',
    menuHover: '#eee',
    dropdownBorder: '#ccc',
    lightBlue: '#6bb8ef',
    lighterBlue: '#e3f4ff',
    lightGray: '#f8f8f8',
    gray: '#808080',
    disabled: '#CCC',
    buttonStatic: '#009ADE',
    buttonHover: '#48c7fc',
    gold: '#bf9f00',
    briefingHeader: '#14508B',
    briefingBackground: '#F3F3F3',
    briefingHighlight: '#E3EBF3',
    red: '#FF0D0D',
};
exports.COLORS = COLORS;
const FPI_COLORS = {
    drynessGreen: '#00B050',
    drynessYellow: '#FFFF0B',
    drynessRed: '#FF0000',
    weatherComponent0: '#2F6FBA',
    weatherComponent1: '#4EADEA',
    weatherComponent2: '#A0CF63',
    weatherComponent3: '#FEFF54',
    weatherComponent4: '#F5C343',
    weatherComponent5: '#EA3323',
    weatherComponent6: '#AF2318',
    fpiColorGreen: COLORS.fieldGreen,
    fpiColorOrange: COLORS.electricOrange,
    fpiColorYellow: COLORS.accessibleYellow,
    fpiColorRed: COLORS.energyRed,
    fpiDataColorPurple: '#6f31a0',
    fpiDataColorRed: '#EA3323',
    fpiDataColorYellow: '#dbdc47',
    fpiDataColorGreen: '#4EAD5B',
    fuelsRed: '#EA3323',
    fuelsOrange: '#F5C343',
    fuelsYellow: '#FEFF54',
    fuelsGreen: '#A0CF63',
    fuelsBlueLight: '#4EADEA',
    fuelsBlueDark: '#2F6FBA',
    default: '#fff',
};
exports.FPI_COLORS = FPI_COLORS;
