"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISTRICTS_LIST = exports.DISTRICTS = void 0;
exports.DISTRICTS = {
    metro: {
        code: 'cm',
        id: 'metro',
        label: 'Metro',
        lat: 32.655326,
        long: -117.05421,
    },
    beachcities: {
        code: 'bc',
        id: 'beachcities',
        label: 'Beach Cities',
        lat: 32.847517,
        long: -117.177999,
    },
    northcoast: {
        code: 'nc',
        id: 'northcoast',
        label: 'North Coast',
        lat: 33.200951,
        long: -117.299694,
    },
    orangecounty: {
        code: 'oc',
        id: 'orangecounty',
        label: 'Orange County',
        lat: 33.463267,
        long: -117.557372,
    },
    northeast: {
        code: 'ne',
        id: 'northeast',
        label: 'North East',
        lat: 33.230672,
        long: -117.029055,
    },
    eastern: {
        code: 'ea',
        id: 'eastern',
        label: 'Eastern',
        lat: 32.80348,
        long: -116.89828,
    },
    ramona: {
        code: 'ra',
        id: 'ramona',
        label: 'Ramona',
        lat: 33.16735,
        long: -116.575476,
    },
    mountainempire: {
        code: 'me',
        id: 'mountainempire',
        label: 'Mountain Empire',
        lat: 32.786002,
        long: -116.529489,
    },
};
exports.DISTRICTS_LIST = [
    exports.DISTRICTS.mountainempire,
    exports.DISTRICTS.ramona,
    exports.DISTRICTS.eastern,
    exports.DISTRICTS.northeast,
    exports.DISTRICTS.orangecounty,
    exports.DISTRICTS.northcoast,
    exports.DISTRICTS.beachcities,
    exports.DISTRICTS.metro,
];
