"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIRE_GROWTH_RATES = void 0;
exports.FIRE_GROWTH_RATES = [
    '',
    'Unknown',
    'Slow',
    'Moderate',
    'Rapid',
    'Extreme'
];
exports.default = { FIRE_GROWTH_RATES: exports.FIRE_GROWTH_RATES };
