"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FPI_DATA_TYPE = exports.FPI_LEVELS = exports.FPI_SUBREGION_CATEGORY_ARRAY = exports.FPI_SUBREGION_CATEGORIES = void 0;
var FPI_SUBREGION_CATEGORIES;
(function (FPI_SUBREGION_CATEGORIES) {
    FPI_SUBREGION_CATEGORIES["foothills"] = "Foothills/Mountains";
    FPI_SUBREGION_CATEGORIES["coastal"] = "Coastal";
    FPI_SUBREGION_CATEGORIES["valleys"] = "Valleys";
})(FPI_SUBREGION_CATEGORIES || (exports.FPI_SUBREGION_CATEGORIES = FPI_SUBREGION_CATEGORIES = {}));
exports.FPI_SUBREGION_CATEGORY_ARRAY = [
    FPI_SUBREGION_CATEGORIES.foothills,
    FPI_SUBREGION_CATEGORIES.coastal,
    FPI_SUBREGION_CATEGORIES.valleys,
];
exports.FPI_LEVELS = {
    normal: { short: 'NO', long: 'Normal' },
    elevated_low: { short: 'EL', long: 'Elevated' },
    elevated_high: { short: 'EL', long: 'Elevated' },
    extreme: { short: 'EX', long: 'Extreme' },
};
var FPI_DATA_TYPE;
(function (FPI_DATA_TYPE) {
    FPI_DATA_TYPE["REGION"] = "region";
    FPI_DATA_TYPE["SEVEN_DAY"] = "seven-day";
})(FPI_DATA_TYPE || (exports.FPI_DATA_TYPE = FPI_DATA_TYPE = {}));
