"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignAqiCategory = exports.checkAqiCategory = void 0;
const constants_1 = require("../constants");
function checkAqiCategory(aqiValue, categoryName) {
    // Based on values from https://www.airnow.gov/aqi/aqi-basics/
    switch (true) {
        case aqiValue <= 50:
            return categoryName == constants_1.AQI_CATEGORIES.Category_1;
        case aqiValue <= 100:
            return categoryName == constants_1.AQI_CATEGORIES.Category_2;
        case aqiValue <= 150:
            return categoryName == constants_1.AQI_CATEGORIES.Category_3;
        case aqiValue <= 200:
            return categoryName == constants_1.AQI_CATEGORIES.Category_4;
        case aqiValue <= 300:
            return categoryName == constants_1.AQI_CATEGORIES.Category_5;
        case aqiValue <= 500:
            return categoryName == constants_1.AQI_CATEGORIES.Category_6;
        default:
            return false;
    }
}
exports.checkAqiCategory = checkAqiCategory;
function assignAqiCategory(aqiValue) {
    // Based on values from https://www.airnow.gov/aqi/aqi-basics/
    if (aqiValue <= 50) {
        return constants_1.AQI_CATEGORIES.Category_1;
    }
    else if (aqiValue <= 100) {
        return constants_1.AQI_CATEGORIES.Category_2;
    }
    else if (aqiValue <= 150) {
        return constants_1.AQI_CATEGORIES.Category_3;
    }
    else if (aqiValue <= 200) {
        return constants_1.AQI_CATEGORIES.Category_4;
    }
    else if (aqiValue <= 300) {
        return constants_1.AQI_CATEGORIES.Category_5;
    }
    else if (aqiValue <= 500) {
        return constants_1.AQI_CATEGORIES.Category_6;
    }
    else {
        return 'Unknown';
    }
}
exports.assignAqiCategory = assignAqiCategory;
