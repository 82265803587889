"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DAY_NAMES = exports.DATETIME_FORMATS = exports.TIME_FRAMES = void 0;
exports.TIME_FRAMES = [
    'Sunday AM',
    'Sunday PM',
    'Monday AM',
    'Monday PM',
    'Tuesday AM',
    'Tuesday PM',
    'Wednesday AM',
    'Wednesday PM',
    'Thursday AM',
    'Thursday PM',
    'Friday AM',
    'Friday PM',
    'Saturday AM',
    'Saturday PM',
];
var DATETIME_FORMATS;
(function (DATETIME_FORMATS) {
    DATETIME_FORMATS["TIME_ZONE"] = "America/Los_Angeles";
    DATETIME_FORMATS["DATE_FORMAT"] = "MM-DD-YYYY";
    DATETIME_FORMATS["DATE_AND_TIME_FORMAT"] = "MM-DD-YYYY HH:mm z";
    DATETIME_FORMATS["TIME_FORMAT"] = "HH:mm";
    DATETIME_FORMATS["DAY_OF_THE_WEEK_PLUS_DATE"] = "dddd MM-DD-YYYY";
    DATETIME_FORMATS["DAY_OF_THE_WEEK_HEADER"] = "ddd A";
    DATETIME_FORMATS["DAY_OF_THE_WEEK_HEADER_DAY_ONLY"] = "ddd";
    DATETIME_FORMATS["DAY_OF_THE_WEEK_LONG"] = "dddd";
    DATETIME_FORMATS["OPI_TIME_BLOCK"] = "MM-DD-YYYY A";
    DATETIME_FORMATS["OPI_OUTAGE_ORIGINAL_FORMAT"] = "MM-DD-YYYY HH:mm";
    DATETIME_FORMATS["OPI_FETCH"] = "MMDDYYYY";
    DATETIME_FORMATS["FPI_ORIGINAL_FORMAT"] = "MM-DD-YYYY HH:mm";
    DATETIME_FORMATS["PUSH_NOTIFICATION"] = "MMM D, YYYY h:mm A";
    DATETIME_FORMATS["TIDE_DATE"] = "YYYYMMDD";
    DATETIME_FORMATS["AQI_DATE"] = "YYYY-MM-DD";
    DATETIME_FORMATS["POWER_DATE"] = "YYYY/MM/DD HH:mm:ss Z";
})(DATETIME_FORMATS || (exports.DATETIME_FORMATS = DATETIME_FORMATS = {}));
var DAY_NAMES;
(function (DAY_NAMES) {
    DAY_NAMES["TODAY"] = "today";
    DAY_NAMES["TOMORROW"] = "tomorrow";
})(DAY_NAMES || (exports.DAY_NAMES = DAY_NAMES = {}));
exports.default = { TIME_FRAMES: exports.TIME_FRAMES, DAY_NAMES, DATETIME_FORMATS };
