"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RAINFALL_AMOUNTS = void 0;
exports.RAINFALL_AMOUNTS = [
    'None',
    'Drizzle',
    'Trace',
    '0.05',
    '0.10',
    '0.25',
    '0.50',
    '0.75',
    '1.0',
    '1.5',
    '2.0',
    '3.0',
    '4.0',
    '5.0',
    '5.0+',
];
exports.default = { RAINFALL_AMOUNTS: exports.RAINFALL_AMOUNTS };
