"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_WIDGETS = exports.WIDGET_DESCRIPTIONS = exports.WIDGET_NAMES = exports.USER_TYPES = void 0;
var USER_TYPES;
(function (USER_TYPES) {
    USER_TYPES["PUBLIC"] = "Public";
    USER_TYPES["AUTHENTICATED"] = "Authenticated";
    USER_TYPES["TRANSMISSION"] = "Transmission";
})(USER_TYPES || (exports.USER_TYPES = USER_TYPES = {}));
var WIDGET_NAMES;
(function (WIDGET_NAMES) {
    WIDGET_NAMES["LOW_TEMP"] = "Low Temp Forecast";
    WIDGET_NAMES["PAL"] = "Project Activity Level (PAL)";
    WIDGET_NAMES["PSPS"] = "PSPS Potential Index";
    WIDGET_NAMES["RAINFALL"] = "Rainfall Forecast";
    WIDGET_NAMES["RED_FLAG"] = "Red Flag Warning";
    WIDGET_NAMES["SAWTI"] = "Santa Ana Wildfire Threat Index";
    WIDGET_NAMES["SNOWFALL"] = "Snowfall Forecast";
    WIDGET_NAMES["SUN"] = "Sunrise & Sunset";
    WIDGET_NAMES["THUNDERSTORM"] = "Thunderstorm Threat";
    WIDGET_NAMES["WIND_GUST_FORECAST"] = "Wind Gust Forecast";
    WIDGET_NAMES["WINTER_STORM"] = "Winter Storm Threat";
    WIDGET_NAMES["FPI"] = "Fire Potential Index (FPI)";
    WIDGET_NAMES["OPI"] = "Outage Potential Index (OPI)";
    WIDGET_NAMES["CURRENT_TEMP"] = "Current Temperatures";
    WIDGET_NAMES["TOP_CURRENT_GUSTS"] = "Top Current Gusts";
    WIDGET_NAMES["CURRENT_RH"] = "Current Relative Humidity";
})(WIDGET_NAMES || (exports.WIDGET_NAMES = WIDGET_NAMES = {}));
exports.WIDGET_DESCRIPTIONS = {
    [WIDGET_NAMES.LOW_TEMP]: `Tonight’s forecasted low temperatures across the service territory.`,
    [WIDGET_NAMES.PAL]: `Project Activity Levels (PALs) provided daily by the US Forest Service.`,
    [WIDGET_NAMES.PSPS]: `Public Safety Power Shutoff (PSPS) Potential Index.`,
    [WIDGET_NAMES.RAINFALL]: `Potential rainfall totals across the region during a forecasted rain event.`,
    [WIDGET_NAMES.RED_FLAG]: `Red Flag Warning issued by the National Weather Service.`,
    [WIDGET_NAMES.SAWTI]: `Santa Ana Wildfire Threat Index (SAWTI) highlights wildfire potential during Santa Ana events.`,
    [WIDGET_NAMES.SNOWFALL]: `Forecasted snowfall accumulations.`,
    [WIDGET_NAMES.SUN]: `Sunrise/sunset times for San Diego.`,
    [WIDGET_NAMES.THUNDERSTORM]: `Details and timing of a forecasted thunderstorm threat.`,
    [WIDGET_NAMES.WIND_GUST_FORECAST]: `Details and timing of significant wind events.`,
    [WIDGET_NAMES.WINTER_STORM]: `Details and timing of a forecasted winter weather event.`,
    [WIDGET_NAMES.FPI]: `The Fire Potential Index (FPI) is a planning and decision support tool that incorporates weather and fuels information to rate the overall fire threat at the district level.`,
    [WIDGET_NAMES.OPI]: `The Outage Potential Index (OPI) is a planning and decision support tool that incorporates high resolution weather forecasts and uses state of the art machine learning methods to predict the potential for weather related outages at the district level.`,
    [WIDGET_NAMES.CURRENT_TEMP]: `Current temperatures across the service territory.`,
    [WIDGET_NAMES.TOP_CURRENT_GUSTS]: `The top five windiest stations at any given time.`,
    [WIDGET_NAMES.CURRENT_RH]: `Current relative humidity values across the service territory.`,
};
exports.DEFAULT_WIDGETS = [
    {
        name: WIDGET_NAMES.RAINFALL,
        selected: true,
        userRole: USER_TYPES.PUBLIC,
        order: 1,
    },
    {
        name: WIDGET_NAMES.SNOWFALL,
        selected: true,
        userRole: USER_TYPES.PUBLIC,
        order: 2,
    },
    {
        name: WIDGET_NAMES.WIND_GUST_FORECAST,
        selected: true,
        userRole: USER_TYPES.PUBLIC,
        order: 3,
    },
    {
        name: WIDGET_NAMES.LOW_TEMP,
        selected: true,
        userRole: USER_TYPES.PUBLIC,
        order: 4,
    },
    {
        name: WIDGET_NAMES.PAL,
        selected: true,
        userRole: USER_TYPES.PUBLIC,
        order: 5,
    },
    {
        name: WIDGET_NAMES.SUN,
        selected: false,
        userRole: USER_TYPES.PUBLIC,
        order: null,
    },
    {
        name: WIDGET_NAMES.SAWTI,
        selected: false,
        userRole: USER_TYPES.PUBLIC,
        order: null,
    },
    {
        name: WIDGET_NAMES.THUNDERSTORM,
        selected: false,
        userRole: USER_TYPES.PUBLIC,
        order: null,
    },
    {
        name: WIDGET_NAMES.FPI,
        selected: false,
        userRole: USER_TYPES.PUBLIC,
        order: null,
    },
    {
        name: WIDGET_NAMES.OPI,
        selected: false,
        userRole: USER_TYPES.PUBLIC,
        order: null,
    },
    {
        name: WIDGET_NAMES.PSPS,
        selected: false,
        userRole: USER_TYPES.PUBLIC,
        order: null,
    },
    {
        name: WIDGET_NAMES.WINTER_STORM,
        selected: false,
        userRole: USER_TYPES.PUBLIC,
        order: null,
    },
    {
        name: WIDGET_NAMES.RED_FLAG,
        selected: false,
        userRole: USER_TYPES.PUBLIC,
        order: null,
    },
    {
        name: WIDGET_NAMES.TOP_CURRENT_GUSTS,
        selected: false,
        userRole: USER_TYPES.PUBLIC,
        order: null,
    },
    {
        name: WIDGET_NAMES.CURRENT_RH,
        selected: false,
        userRole: USER_TYPES.PUBLIC,
        order: null,
    },
    {
        name: WIDGET_NAMES.CURRENT_TEMP,
        selected: false,
        userRole: USER_TYPES.PUBLIC,
        order: null,
    },
    {
        name: WIDGET_NAMES.RAINFALL,
        selected: true,
        userRole: USER_TYPES.AUTHENTICATED,
        order: 1,
    },
    {
        name: WIDGET_NAMES.SNOWFALL,
        selected: true,
        userRole: USER_TYPES.AUTHENTICATED,
        order: 2,
    },
    {
        name: WIDGET_NAMES.WIND_GUST_FORECAST,
        selected: true,
        userRole: USER_TYPES.AUTHENTICATED,
        order: 3,
    },
    {
        name: WIDGET_NAMES.LOW_TEMP,
        selected: true,
        userRole: USER_TYPES.AUTHENTICATED,
        order: 4,
    },
    {
        name: WIDGET_NAMES.PAL,
        selected: true,
        userRole: USER_TYPES.AUTHENTICATED,
        order: 5,
    },
    {
        name: WIDGET_NAMES.SUN,
        selected: false,
        userRole: USER_TYPES.AUTHENTICATED,
        order: null,
    },
    {
        name: WIDGET_NAMES.SAWTI,
        selected: false,
        userRole: USER_TYPES.AUTHENTICATED,
        order: null,
    },
    {
        name: WIDGET_NAMES.THUNDERSTORM,
        selected: false,
        userRole: USER_TYPES.AUTHENTICATED,
        order: null,
    },
    {
        name: WIDGET_NAMES.FPI,
        selected: false,
        userRole: USER_TYPES.AUTHENTICATED,
        order: null,
    },
    {
        name: WIDGET_NAMES.OPI,
        selected: false,
        userRole: USER_TYPES.AUTHENTICATED,
        order: null,
    },
    {
        name: WIDGET_NAMES.PSPS,
        selected: false,
        userRole: USER_TYPES.AUTHENTICATED,
        order: null,
    },
    {
        name: WIDGET_NAMES.WINTER_STORM,
        selected: false,
        userRole: USER_TYPES.AUTHENTICATED,
        order: null,
    },
    {
        name: WIDGET_NAMES.RED_FLAG,
        selected: false,
        userRole: USER_TYPES.AUTHENTICATED,
        order: null,
    },
    {
        name: WIDGET_NAMES.TOP_CURRENT_GUSTS,
        selected: false,
        userRole: USER_TYPES.AUTHENTICATED,
        order: null,
    },
    {
        name: WIDGET_NAMES.CURRENT_RH,
        selected: false,
        userRole: USER_TYPES.AUTHENTICATED,
        order: null,
    },
    {
        name: WIDGET_NAMES.CURRENT_TEMP,
        selected: false,
        userRole: USER_TYPES.AUTHENTICATED,
        order: null,
    },
    {
        name: WIDGET_NAMES.RAINFALL,
        selected: false,
        userRole: USER_TYPES.TRANSMISSION,
        order: null,
    },
    {
        name: WIDGET_NAMES.SNOWFALL,
        selected: true,
        userRole: USER_TYPES.TRANSMISSION,
        order: 1,
    },
    {
        name: WIDGET_NAMES.WIND_GUST_FORECAST,
        selected: true,
        userRole: USER_TYPES.TRANSMISSION,
        order: 2,
    },
    {
        name: WIDGET_NAMES.LOW_TEMP,
        selected: true,
        userRole: USER_TYPES.TRANSMISSION,
        order: 3,
    },
    {
        name: WIDGET_NAMES.PAL,
        selected: true,
        userRole: USER_TYPES.TRANSMISSION,
        order: 4,
    },
    {
        name: WIDGET_NAMES.SUN,
        selected: true,
        userRole: USER_TYPES.TRANSMISSION,
        order: 5,
    },
    {
        name: WIDGET_NAMES.SAWTI,
        selected: false,
        userRole: USER_TYPES.TRANSMISSION,
        order: null,
    },
    {
        name: WIDGET_NAMES.THUNDERSTORM,
        selected: false,
        userRole: USER_TYPES.TRANSMISSION,
        order: null,
    },
    {
        name: WIDGET_NAMES.FPI,
        selected: false,
        userRole: USER_TYPES.TRANSMISSION,
        order: null,
    },
    {
        name: WIDGET_NAMES.OPI,
        selected: false,
        userRole: USER_TYPES.TRANSMISSION,
        order: null,
    },
    {
        name: WIDGET_NAMES.PSPS,
        selected: false,
        userRole: USER_TYPES.TRANSMISSION,
        order: null,
    },
    {
        name: WIDGET_NAMES.WINTER_STORM,
        selected: false,
        userRole: USER_TYPES.TRANSMISSION,
        order: null,
    },
    {
        name: WIDGET_NAMES.RED_FLAG,
        selected: false,
        userRole: USER_TYPES.TRANSMISSION,
        order: null,
    },
    {
        name: WIDGET_NAMES.TOP_CURRENT_GUSTS,
        selected: false,
        userRole: USER_TYPES.TRANSMISSION,
        order: null,
    },
    {
        name: WIDGET_NAMES.CURRENT_RH,
        selected: false,
        userRole: USER_TYPES.TRANSMISSION,
        order: null,
    },
    {
        name: WIDGET_NAMES.CURRENT_TEMP,
        selected: false,
        userRole: USER_TYPES.TRANSMISSION,
        order: null,
    },
];
