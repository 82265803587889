"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXPECTED_WEATHER_CONDITIONS = void 0;
exports.EXPECTED_WEATHER_CONDITIONS = [
    'Sunny',
    'Partly Cloudy',
    'Cloudy',
    'Rainy',
    'Windy'
];
