"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PUSH_NOTIFICATION_SDGE_RESPONSE_OPTIONS = exports.PUSH_NOTIFICATION_SDGE_RELATED_OPTIONS = exports.PUSH_NOTIFICATION_SDGE_THREAT_OPTIONS = exports.PUSH_NOTIFICATION_TYPES_LIST = exports.PUSH_NOTIFICATION_TYPES = void 0;
exports.PUSH_NOTIFICATION_TYPES = {
    weather: 'Weather',
    fire: 'Fire',
    gas: 'Gas',
    seismic_event: 'Seismic Event',
    system: 'System',
    other_incident: 'Other Incident',
};
exports.PUSH_NOTIFICATION_TYPES_LIST = [
    exports.PUSH_NOTIFICATION_TYPES.weather,
    // Saving in case multiple incident types are wanted in the rebuild
    // Switching between different forms is already implemented, this
    // limits it to only the weather briefing type for now
    // PUSH_NOTIFICATION_TYPES.fire,
    // PUSH_NOTIFICATION_TYPES.gas,
    // PUSH_NOTIFICATION_TYPES.seismic_event,
    // PUSH_NOTIFICATION_TYPES.system,
    // PUSH_NOTIFICATION_TYPES.other_incident,
];
exports.PUSH_NOTIFICATION_SDGE_THREAT_OPTIONS = ['', 'Immediate', 'Delayed', 'Unlikely', 'Unknown'];
exports.PUSH_NOTIFICATION_SDGE_RELATED_OPTIONS = [
    '',
    'Under Investigation',
    'Unrelated',
    'Possibly Related',
    'Unknown',
];
exports.PUSH_NOTIFICATION_SDGE_RESPONSE_OPTIONS = [
    '',
    'En Route',
    'On Scene',
    'Not Requested',
    'Not Applicable',
    'Leaving Scene',
];
