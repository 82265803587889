"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ELEVATION_LEVELS = exports.SNOWFALL_AMOUNTS = void 0;
exports.SNOWFALL_AMOUNTS = [
    'None',
    'Dusting',
    '1"',
    '2"',
    '3"',
    '4"',
    '5"',
    '6"',
    '7"',
    '8"',
    '9"',
    '10"',
    '12"',
    '18"',
    '18"+',
];
/// Elevation in feet
exports.ELEVATION_LEVELS = [1000, 2000, 3000, 4000, 5000];
