"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.THREATS = exports.THREAT_COLORS = exports.THREAT_COLORS_OBJ = exports.THREAT_AREAS = void 0;
exports.THREAT_AREAS = [
    'All Districts',
    'Beach',
    'Beach and Valleys',
    'Deserts',
    'Inland Districts',
    'Mountains',
    'Mountains and Deserts',
    'Valleys',
];
exports.THREAT_COLORS_OBJ = {
    green: { long: 'Green', short: 'NO' },
    yellow: { long: 'Yellow', short: 'EL' },
    red: { long: 'Red', short: 'EX' },
};
exports.THREAT_COLORS = [
    exports.THREAT_COLORS_OBJ.green.long,
    exports.THREAT_COLORS_OBJ.yellow.long,
    exports.THREAT_COLORS_OBJ.red.long,
];
exports.THREATS = [
    'Wind and Rain',
    'Hail',
    'Wind',
    'Flooding',
    'Gusty Winds',
    'Damaging Winds',
    'Lightning',
];
